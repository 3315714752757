<template>
  <nav class="nav" :class="{'nav-small': !toogleIcon}">
    <div class="nav-left" @click="toogleIcon = !toogleIcon, selectedSubMenu = false">
      <img
        :src="require('@/assets/icons/nav/double-caret-left.png')"
        :style="
          toogleIcon
            ? 'transform: rotate(0deg); transition: all 0.5s ease;'
            : 'transform: rotate(180deg); transition: all 0.5s ease;'
        "
        alt="double-caret-left"
      />
    </div>
    <div style="overflow-y: auto; height: 100%;">
      <div class="nav__main">
        <div
          :class="{
            nactive: item.slug === '/' ? $route.fullPath === item.slug : $route.fullPath.includes(item.slug),
            mactive: selectedSubMenu === item.id && toogleIcon,
          }"
          v-for="item in role_navs.menu"
          :key="item.id"
          @click="item.submenu && toogleIcon ? toggleSub(item.id) : !toogleIcon && item.submenu ? '' : $router.push(item.slug)"
          @mouseover.stop="!toogleIcon ? getBlockPosition(item.id, $event.target) : ''"
          @mouseleave.stop="!toogleIcon ? hoveredItem = false : ''"
        >
          <div class="nav__main-item">
            <img
              class="img"
              :src="require('@/assets/icons/nav/' + item.icon + '.png')"
              :alt="item.icon"
            />
            <p class="ptext" v-if="toogleIcon">{{ item.title }}</p>
            <img
              class="img"
              v-if="item.submenu && toogleIcon"
              :src="require('@/assets/icons/nav/right.png')"
              :alt="item.icon"
              :style="
                selectedSubMenu === item.id
                  ? 'transform: rotate(-90deg); transition: all 0.5s ease;'
                  : 'transform: rotate(0deg); transition: all 0.5s ease;'
              "
            />
            <div v-if="shouldShowSubMenu && hoveredItem === item.id" class="nav__main-hoverlist" :style="'top:' + block_pos + 'px;'">
              <div class="nav__main-hoverlist-title">
                {{ item.title }}
                <img :src="require('@/assets/icons/chevron-right.png')" alt="chevron" />
              </div>
              <div
                class="nav__main-hoverlist-elem"
                v-for="elem in shouldShowSubMenu"
                :key="elem.id"
                @click="$router.push(elem.slug)"
              >
                <img :src="require('@/assets/icons/nav/' + elem.icon + '.png')" :alt="elem.icon" />
                <p>{{ elem.title }}</p>
              </div>
            </div>
          </div>
          <div v-if="selectedSubMenu === item.id && toogleIcon" class="nav__main-list" @click="selectedSubMenu = true">
            <div
              class="nav__main-list-elem"
              v-for="elem in item.submenu"
              :key="elem.id"
              :class="{
                nactive: $route.fullPath === elem.slug
              }"
              @click="selectedSubMenu = false, $router.push(elem.slug)"
            >
              <img
                :src="require('@/assets/icons/nav/' + elem.icon + '.png')"
                :alt="elem.icon"
              />
              <p v-if="toogleIcon">{{ elem.title }}</p>
            </div>
          </div>
        </div>
      </div>
      <hr v-if="getRole === 'admin' || getRole === 'superadmin'" />
      <div  v-if="getRole === 'admin' || getRole === 'superadmin'"  class="nav__main" style="margin-bottom: 40px;">
        <div
          :class="{
            nactive: $route.fullPath === item.slug,
            mactive: selectedSubMenu === item.id && toogleIcon,
          }"
          v-for="item in role_navs.references"
          :key="item.id"
          @click="item.submenu && toogleIcon ? toggleSub(item.id) : !toogleIcon && item.submenu ? '' : $router.push(item.slug)"
          @mouseover="!toogleIcon ? getBlockPosition(item.id, $event.target) : ''"
          @mouseleave="!toogleIcon ? hoveredItem = false : ''"
        >
          <div class="nav__main-item">
            <img
              class="img"
              :src="require('@/assets/icons/nav/' + item.icon + '.png')"
              :alt="item.icon"
            />
            <p class="ptext" v-if="toogleIcon">{{ item.title }}</p>
            <img
              class="img"
              v-if="item.submenu && toogleIcon"
              :src="require('@/assets/icons/nav/right.png')"
              :alt="item.icon"
              :style="
                selectedSubMenu === item.id
                  ? 'transform: rotate(-90deg); transition: all 0.5s ease;'
                  : 'transform: rotate(0deg); transition: all 0.5s ease;'
              "
            />
            <div v-if="shouldShowSubMenu && hoveredItem === item.id" class="nav__main-hoverlist spravs" :style="'top:' + (block_pos-400) + 'px;'">
              <div class="nav__main-hoverlist-title">
                {{ item.title }}
                <img :src="require('@/assets/icons/chevron-right.png')" alt="chevron" />
              </div>
              <div class="lists">
                <div
                  class="nav__main-hoverlist-elem"
                  v-for="elem in shouldShowSubMenu"
                  :key="elem.id"
                  @click="$router.push(elem.slug)"
                >
                  <img :src="require('@/assets/icons/nav/' + elem.icon + '.png')" :alt="elem.icon" />
                  <p>{{ elem.title }}</p>
                </div>
              </div>
            </div>
          </div>
          <div v-if="selectedSubMenu === item.id" class="nav__main-list" @click="selectedSubMenu = true">
            <div
              class="nav__main-list-elem"
              v-for="elem in item.submenu"
              :key="elem.id"
              @click="$router.push(elem.slug)"
            >
              <img
                :src="require('@/assets/icons/nav/' + elem.icon + '.png')"
                :alt="elem.icon"
              />
              <p v-if="toogleIcon">{{ elem.title }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="nav__main-item">
      <img :src="require('@/assets/icons/nav/logout.png')" alt="logout" />
      <p>Выход</p>
    </div> -->
  </nav>
</template>
<script>
import navs from "@/data/nav.json"
import { mapGetters } from "vuex";
export default {
  data: () => ({
    nav: navs,
    toogleIcon: true,
    selectedSubMenu: false,
    hoveredItem: false,
    block_pos: 0,
  }),
  methods: {
    toggleSub(value) {
      this.selectedSubMenu === value
        ? (this.selectedSubMenu = false)
        : (this.selectedSubMenu = value);
    },
    getBlockPosition(value, event) {
      const rect = event.getBoundingClientRect();
      let its = [1, 7, 15]
      if (its.some(it => it === value)) {
        console.log('sda', rect);
        this.block_pos = rect.top
        this.hoveredItem = value
      }      
    }
  },
  computed: {
    ...mapGetters(["getRole"]),
    role_navs() {
      return this.nav[this.getRole] || false
    },
    shouldShowSubMenu() {
      if (this.nav[this.getRole]) {
        if (this.getRole === 'admin') {
          return this.nav[this.getRole].menu.find(item => item.id === this.hoveredItem)?.submenu ? this.nav[this.getRole].menu.find(item => item.id === this.hoveredItem)?.submenu : this.nav[this.getRole].references.find(item => item.id === this.hoveredItem)?.submenu
        } else {
          return this.hoveredItem !== false && this.nav[this.getRole].menu.find(item => item.id === this.hoveredItem)?.submenu
        }        
      } else {
        return []
      }      
    },
  },
  watch: {
    $route() {
      this.selectedSubMenu = false;
      let finds = this.nav[this.getRole].menu.find(item => {
        if (item?.submenu?.length) {
          return item.submenu.find(elem => {return elem.slug == this.$route.fullPath})
        }        
      })
      if (finds) {
        this.selectedSubMenu = finds.id;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.nav {
  width: min(max(220px, calc(13.75rem + ((1vw - 9.92px) * 12.3922))), 335px);
  min-width: min(max(220px, calc(13.75rem + ((1vw - 9.92px) * 12.3922))), 335px);
  height: 100%;
  background: #ffffff;
  position: relative;
  /* overflow-y: auto; */
  transition: all 0.5s ease;
  &-left {
    position: absolute;
    z-index: 6;
    top: 16px;
    right: -12px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    transition: all 1s ease;
    &:hover {
      cursor: pointer;
      background: #252525;
      img {
        filter: brightness(100);
      }
    }
    @media screen and (max-width: $tablet) {
     display: none;
    }
  }
  hr {
   margin: 15px 0;
   height: 1px;
   border-top: 0.1px solid #E0E0E0;
  }
  &__main {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: stretch;
    &-item {
      display: flex;
      align-items: center;
      position: relative;
      gap: 10px;
      padding: 15px min(max(10px, calc(0.625rem + ((1vw - 9.92px) * 2.1552))), 30px) 16px min(max(20px, calc(1.25rem + ((1vw - 9.92px) * 3.2328))), 50px);
      .ptext {
        flex-grow: 1;
        font-weight: 500;
        font-size: 16px;
        color: #333333;
      }
      @media screen and (min-width: $tablet) {
        &:hover {
          cursor: pointer;
          background: #333333;
          .ptext {
            color: #f3f33e;
          }
          .img {
            filter: invert(100%) sepia(100%) hue-rotate(10deg) contrast(100%);
          }
        }
      }
      @media screen and (max-width: $tablet) {
       margin: 0 16px ;
       padding: 16px 0;
       border-top: 1px solid #E0E0E0;
       border-bottom: 1px solid #E0E0E0;
      }
    }
    &-hoverlist {
      position: fixed;
      display: flex;
      flex-direction: column;
      gap: 10px;
      left: min(max(70px, calc(4.375rem + ((1vw - 9.92px) * 5.819))), 124px) !important;
      z-index: 888;
      background: #333333;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      padding-bottom: 10px;
      width: 252px;
      &-title {
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        color: #F3F33E;
        padding: 15px 16px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      }
      &-elem {
        display: flex;
        width: 100%;
        align-items: center;
        gap: 10px;
        color: #bdbdbd !important;
        padding: 10px 20px;
        font-size: 14px;
        &:hover {
          cursor: pointer;
          background: #333333;
          p {
            color: #f3f33e;
          }
          img {
            filter: invert(100%) sepia(0%) saturate(100%) hue-rotate(60deg) brightness(100%) contrast(0%);
          }
        }
      }
    }
    &-list {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: stretch;
      padding: 14px min(max(25px, calc(1.5625rem + ((1vw - 9.92px) * 2.4784))), 48px) 16px min(max(40px, calc(2.5rem + ((1vw - 9.92px) * 3.2328))), 70px);
      gap: 18px;
      &-elem {
        display: flex;
        align-items: center;
        gap: 10px;
        color: #bdbdbd;
        font-size: 14px;
        &:hover {
          cursor: pointer;
          background: #333333;
          color: #f3f33e;
          img {
            filter: invert(100%) sepia(100%) hue-rotate(10deg) contrast(100%);
          }
        }
      }
      .nactive {
        background: #333333;
        color: #f3f33e;
        img {
          filter: invert(100%) sepia(100%) hue-rotate(10deg) contrast(100%);
        }
      }
    }
    @media screen and (max-width: $tablet) {
     padding-bottom: 40px;
      }
  }
  @media screen and (max-width: $tablet) {
   min-width: 100%;
   width: 100%;
   background: #fafafa;
   hr {
    display: none;
   }
  }
}
.nactive {
  background: #333333;
  .nav__main-item {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
  .ptext {
    color: #f3f33e;
  }
  .img {
    filter: invert(100%) sepia(100%) hue-rotate(10deg) contrast(100%);
  }
}
.mactive {
  background: #333333;
  .nav__main-item {
    p {
      color: #f3f33e;
    }
    img {
      filter: invert(100%) sepia(100%) hue-rotate(10deg) contrast(100%);
    }
  }
}
.nav-small {
  width: min(max(70px, calc(4.375rem + ((1vw - 9.92px) * 5.819))), 124px) !important;
  min-width: min(max(70px, calc(4.375rem + ((1vw - 9.92px) * 5.819))), 124px) !important;
  transition: all 0.5s ease;
}

.spravs {
  left: min(max(70px, calc(4.375rem + ((1vw - 9.92px) * 5.819))), 124px) !important;
  width: 300px;
  max-height: 57vh;
  .lists {
    max-height: 80%;
    overflow-y: auto;
    padding-bottom: 20px;
  }
}
</style>