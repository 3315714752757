<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
import MainLayout from "@/views/layouts/main";
import AuthLayout from "@/views/layouts/auth";
import ErrorLayout from "@/views/layouts/error";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "App",
  components: { MainLayout, AuthLayout, ErrorLayout },
  data: () => ({}),
  computed: {
    ...mapGetters(["getAuth"]),
    layout() {
      return (this.$route.meta.layout || "main") + "-layout";
    },
  },
  methods: {
    ...mapActions([
      "requestUser",
      "ACTUAL_DATA_QR_REMOVE",
      "ACTUAL_DATA_REMOVE",
    ]),
  },
  async mounted() {
    if (this.getAuth) {
      await this.requestUser();
    }
  },
  watch: {
    $route() {
      console.log(this.$router.options.history.state.back);
      if (this.$router.options.history.state.back !== "/qr") {
        this.ACTUAL_DATA_QR_REMOVE();
      }
      if (
        this.$router.options.history.state.back !==
          "/inventory/main-warehouse-check" &&
        this.$router.options.history.state.back !==
          "/inventory/old-batteries?check=true" && 
        this.$router.options.history.state.back !== 
        '/inventory/temporary-warehouse?check=true&type=store' && 
        this.$router.options.history.state.back !== 
        '/inventory/temporary-warehouse?check=true&type=defect'
      ) {
        this.ACTUAL_DATA_REMOVE();
      }
    },
  },
};
</script>

<style lang="scss">
html,
body,
p {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  width: 1px;
  height: 0;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #b247a7;
}
::-webkit-scrollbar:hover {
  background-color: #b247a7;
}
::-webkit-scrollbar-button {
  background: #f8f8f8;
}
</style>
