<template>
  <div class="mcontainer">
    <Header v-if="getAuth" />
    <div class="mcontainer__content">
      <div class="mcontainer__content-view">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import Nav from "@/components/layouts/NavBlock.vue";
import Header from "@/components/layouts/HeaderBlock.vue";
import { mapGetters } from "vuex";
export default {
  components: { Header, Nav },
  computed: {
    ...mapGetters(["getAuth"])
  }
}
</script>

<style lang="scss">
.mcontainer {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  &__content {
    flex-grow: 1;
    display: flex;
    align-items: stretch;
    overflow: auto;
    &-view {
      position: relative;
      z-index: 0;
      flex-grow: 1;
      width: 100%;
      height: 100%;
      overflow-y: auto;
      @media screen and (max-width: $tablet) {
        position: fixed;    
        height: calc(100% - 60px);   
        background: #fafafa;
        display: flex;
        flex-direction: column;
        align-items: stretch;
      }
    }
  }
}
.main-flex {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: stretch;
  position: relative;
}

.main {
  flex-grow: 1;
}

html,
body,
p {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  width: 1px;
  height: 0;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #B247A7;
}
::-webkit-scrollbar:hover {
  background-color: #B247A7;
}
::-webkit-scrollbar-button {
  background: #F8F8F8;
}

</style>
