import axios from 'axios';
import { createStore } from 'vuex'

export default createStore({
  state: {
    auth: localStorage.getItem('access_token') || false,
    user_role: localStorage.getItem("role") || false,
    user: false,
    roles_crm: [],
    branches: [],
    nots: [],
    shops: [],
    manufacturers: [],
    brands: [],
    quarantee: [],
    actualData: JSON.parse(localStorage.getItem('actualData')) || false,
    actualDataQR: JSON.parse(localStorage.getItem('actualDataQR')) || []
  },
  getters: {
    getAuth: (state) => state.auth,
    getRole: (state) => state.user_role,
    getRolesCRM: (state) => state.roles_crm,
    getBranches: (state) => state.branches,
    getShops: (state) => state.shops,
    getUser: (state) => state.user,
    getBrands: (state) => state.brands,
    getManufacturers: (state) => state.manufacturers,
    getQuarantee: (state) => state.quarantee,
    getActualData: (state) => state.actualData,
    getActualDataQR: (state) => state.actualDataQR,
    getNots: (state) => state.nots,
  },
  mutations: {
    SET_AUTH(state, value) {
      state.auth = value;
    },
    SET_USER(state, value) {
      state.user = value;
    },
    SET_ROLE(state, value) {
      state.user_role = value;
    },
    SET_ROLESCRM(state, value) {
      state.roles_crm = value;
    },
    SET_BRANCHES(state, value) {
      state.branches = value;
    },
    SET_SHOPS(state, value) {
      state.shops = value;
    },
    SET_MANUFACTURERS(state, value) {
      state.manufacturers = value;
    },
    SET_BRANDS(state, value) {
      state.brands = value;
    },
    SET_QUARANTEE(state, value) {
      state.quarantee = value;
    },
    SET_ACTUAL_DATA(state, value) {
      state.actualData = value;
    },
    SET_ACTUAL_DATA_QR(state, value) {
      state.actualDataQR = value;
    },
    SET_NOTS(state, value) {
      state.nots = value;
    }
  },
  actions: {
    LOGIN({ commit }, user) {
      localStorage.setItem("role", user.role)
      localStorage.setItem("access_token", user.accessToken)
      commit("SET_ROLE", user.role)
      commit("SET_AUTH", true)
      commit("SET_USER", user.userData);
    },    
    async requestUser({ commit }) {
      axios.get("users/me", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token") || null}`,
        },
      }).then(response => {
        localStorage.setItem("role", response.data.user.userInfo.roleInfo.role)
        commit("SET_USER", response.data.user);
        commit("SET_AUTH", true);
        commit("SET_ROLE", response.data.user.userInfo.roleInfo.role)
      }).catch(err => {
        localStorage.removeItem("role")
        commit("SET_USER", false)
        commit("SET_AUTH", false)
        commit("SET_ROLE", false)
      })
    },
    LOGOUT({ commit }) {
      localStorage.removeItem("role")
      localStorage.removeItem("access_token")
      commit("SET_ROLE", false)
      commit("SET_USER", false)
      commit("SET_AUTH", false)
    },
    async getNotifications({commit}) {
      await axios.get("notifications", {
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("access_token") || null
          }`,
        },
      }).then(res => {
        commit("SET_NOTS", res.data.data)
      }).catch(err => {
        commit("SET_NOTS", [])
      })
    },
    async GET_ROLESCRM({commit}) {
      await axios.get('refs/roles')
        .then(res => {
          commit("SET_ROLESCRM", res.data.data)
        })
        .catch(err => {
          commit("SET_ROLESCRM", [])
        })
    },
    async GET_BRANCHES({commit}) {
      await axios.get('refs/branches')
        .then(res => {
          commit("SET_BRANCHES", res.data.data)
        })
        .catch(err => {
          commit("SET_BRANCHES", [])
        })
    },
    async GET_MANUFACTURERS({commit}) {
      await axios.get('refs/manufacturer_country')
        .then(res => {
          commit("SET_MANUFACTURERS", res.data.data)
        })
        .catch(err => {
          commit("SET_MANUFACTURERS", [])
        })
    },
    async GET_BRANDS({commit}) {
      await axios.get('refs/brands')
        .then(res => {
          commit("SET_BRANDS", res.data.data)
        })
        .catch(err => {
          commit("SET_BRANDS", [])
        })
    },
    async GET_QUARANTEE({commit}) {
      await axios.get('refs/guarantee_period')
        .then(res => {
          commit("SET_QUARANTEE", res.data.data)
        })
        .catch(err => {
          commit("SET_BRANDS", [])
        })
    },
    async GET_SHOPS({commit}, value) {
      await axios.get(`shops${value ? '/' + value : ''}`)
        .then(res => {
          commit("SET_SHOPS", res.data.data)
        })
        .catch(err => {
          commit("SET_SHOPS", [])
        })
    },
    
    async ACTUAL_DATA({commit}, array) {
      localStorage.setItem('actualData', JSON.stringify(array))
      commit("SET_ACTUAL_DATA", array)
    },  
    
    async ACTUAL_DATA_QR({commit, state}, array) {
      let actData = state.actualDataQR
      if (!actData.some(elem => elem.id === array.id)) {
        actData.push(array)
      }      
      localStorage.setItem('actualDataQR', JSON.stringify(actData))
      commit("SET_ACTUAL_DATA_QR", actData)
    },
    
    async ACTUAL_DATA_QR_DELETE({commit, state}, array) {
      let actData = state.actualDataQR
      localStorage.setItem('actualDataQR', JSON.stringify(actData.filter(item => !array.includes(item.id))))
      commit("SET_ACTUAL_DATA_QR", actData.filter(item => !array.includes(item.id)))
    },
    async ACTUAL_DATA_QR_REMOVE({commit}, ) {
      localStorage.removeItem('actualDataQR')
      commit("SET_ACTUAL_DATA_QR", [])
    },
    async ACTUAL_DATA_REMOVE({commit}, ) {
      localStorage.removeItem('actualData')
      commit("SET_ACTUAL_DATA", false)
    },
  },
  modules: {
  }
})
