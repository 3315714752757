<template>
  <header class="header">
    <div class="header-logo" @click="$router.push('/')" >
      <img :src="require('@/assets/icons/Logo.png')" alt="logo" />
    </div>
    <div class="header__center">
      <div class="header__center-input" v-if=" getUser?.userInfo?.role_id !== 7">
        <img :src="require('@/assets/icons/search.png')" alt="search" />
        <input placeholder="Поиск" v-model="search" />
      </div>
    </div>
    <div class="header__account">
      <div style="position: relative;" v-if="getUser?.userInfo?.role_id !== 7 && getNots.length">
        <div class="header__account-notice"  @click.stop="toggleNotific">
          <img :src="require('@/assets/icons/notice.png')" alt="notice" />
          <p>{{ getNots.length }}</p>
        </div>
        <div class="header__account-noticetoggle" :class="{'togac': toggleNot}" v-click-outside="closeNotific">
          <div class="header__account-noticetoggle-lists">
            <p v-for="item in getNots.slice(0, 3)" :key="item.id" v-html="item.notification"></p>
          </div>
          <div class="header__account-noticetoggle-a"><router-link to="/notifications">Подробнее</router-link></div>
        </div>
      </div>
      <div style="position: relative;" v-if="getUser">
        <div class="header__account-profile" @click.stop="toggleProfile">
          <div class="header__account-profile-ava">
              <img :src="getUser.userInfo.image ? $cdn + getUser.userInfo.image : require('@/assets/icons/user_profile.png')" alt="user_profile" />
          </div>
          <div class="header__account-profile-name">
            <p v-if="getUser.userInfo.surname && getUser.userInfo.name">{{getUser.userInfo.surname + ' ' + getUser.userInfo.name.slice(0, 1) + '.'}}</p>
            <span>{{getUser.userInfo.roleInfo.name}}</span>
          </div>
          <div class="header__account-profile-arrow">
            <img :src="require('@/assets/icons/down.png')" alt="down" />
          </div>
        </div>
        <div class="header__account-toggle" :class="{'togac': toggleAccount}" v-click-outside="closeToggle">
          <div class="header__account-toggle-profile">       
            <div class="img">
              <img :src="getUser.userInfo.image ? $cdn + getUser.userInfo.image : require('@/assets/icons/user_profile.png')" alt="user_profile" />
            </div>     
            <span>{{getUser.userInfo.roleInfo.name}}</span>
            <span>{{getUser.userInfo.email}}</span>
          </div>
          <div class="header__account-toggle-buttons">
            <button @click="$router.push('/profile')">
              <img :src="require('@/assets/icons/user.png')" alt="user" />
              Профиль
            </button>
            <button @click="logout">
              <img :src="require('@/assets/icons/nav/logout.png')" alt="logout" />
              Выход
            </button>
          </div>
        </div>
      </div>
    </div>
  </header>
  <header class="mheader">
    <div @click="$router.push(item.slug)" v-for="item in mheaderGet" :class="{mheadactive: $route.fullPath === item.slug}" :key="item.id" class="mheader-item">
      <img :src="require('@/assets/icons/' + item.icon + '.png')" :alt="item.icon" />
      {{ item.title }}
    </div>
    <div @click="openMenu" :class="{mheadactive: toggleMenu}" class="mheader-item">
      <img :src="require('@/assets/icons/menu.png')" alt="menu" />
      Меню
    </div>
  </header>
  <div class="mnav" :class="{'mnav-active': toggleMenu}">
    <img @click="toggleMenu = false" class="mnav-left" :src="require('@/assets/icons/left.png')" alt="left" />
    <NavBlock />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import NavBlock from './NavBlock.vue';
export default {
  components: { NavBlock },
  data: () => ({
    search: "",
    toggleMenu: false,
    toggleNot: false,
    toggleAccount: false,
    mheader: [
      {
        id: 0,
        title: 'Главная',
        icon: 'home',
        slug: '/'
      },
      {
        id: 1,
        title: 'Voltman QR',
        icon: 'qr',
        slug: '/qr'
      },
      {
        id: 2,
        title: 'Уведомления',
        icon: 'chat',
        slug: '/notifications'
      },
      {
        id: 3,
        title: 'Профиль',
        icon: 'profile',
        slug: '/profile'
      },
    ]
  }),
  computed: {
    ...mapGetters(["getUser", "getRole", "getNots"]),
    mheaderGet() {
      if (this.getRole === 'worker' || this.getRole === 'courier' || this.getRole === 'manager') {
        return [
      {
        id: 0,
        title: 'Главная',
        icon: 'home',
        slug: '/'
      },
      {
        id: 1,
        title: 'Voltman QR',
        icon: 'qr',
        slug: '/qr'
      },
      {
        id: 2,
        title: 'Уведомления',
        icon: 'chat',
        slug: '/notifications'
      },
      {
        id: 3,
        title: 'Профиль',
        icon: 'profile',
        slug: '/profile'
      },
    ]
      } else {
        return [
      {
        id: 0,
        title: 'Главная',
        icon: 'home',
        slug: '/'
      },
      {
        id: 2,
        title: 'Уведомления',
        icon: 'chat',
        slug: '/notifications'
      },
      {
        id: 3,
        title: 'Профиль',
        icon: 'profile',
        slug: '/profile'
      },
    ]
      }
    }
  },
  created() {
    // this.getNotifications()
  },
  methods: {
    ...mapActions(["LOGOUT", "getNotifications"]),
    openMenu() {
      this.toggleMenu = !this.toggleMenu
    },
    toggleProfile() {
      this.toggleAccount = !this.toggleAccount
    },
    closeToggle() {
      setTimeout(() => {
        this.toggleAccount ? this.toggleAccount = false : ''       
      }, 10);
    },
    toggleNotific() {
      this.toggleNot = !this.toggleNot
    },
    closeNotific() {
      setTimeout(() => {
        this.toggleNot ? this.toggleNot = false : ''       
      }, 10);
    },
    logout() {
      this.LOGOUT()
      this.$router.push('/auth/login')
      location.reload()
    }
  },
  watch: {
    $route() {
      setTimeout(() => {
        this.toggleMenu = false
        this.toggleAccount = false
      }, 10);
      this.toggleNot = false
    }
  }
};
</script>
<style lang="scss" scoped>
.header {
  height: min(max(60px, calc(3.75rem + ((1vw - 9.92px) * 4.3103))), 100px);
  min-height: min(max(60px, calc(3.75rem + ((1vw - 9.92px) * 4.3103))), 100px);
  background: #252525;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
  gap: 90px;
  &-logo {
    height: 70%;
    max-height: 62px;
    cursor: pointer;
    img {
      height: 100%;
    }
  }
  &__center {
    height: 60%;
    max-height: 55px;
    flex-grow: 1;
    &-input {
     padding: 0px 20px;
     background: #FFFFFF;
     max-width: 607px;
     height: 100%;
     display: flex;
     align-items: center;
     input {
      margin-top: 2px;
      outline: none;
      border: none;
      font-size: 14px;
      flex-grow: 1;
      padding-left: 12px;
     }
    }
  }
  &__account {
    display: flex;
    align-items: center;
    gap: 24px;
    height: 60%;
    max-height: 55px;
    &-notice {
      position: relative;
      p {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 0;
        right: 0;
        color: #FFFE0D;
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &:hover {
        cursor: pointer;
      }
    }
    &-profile {
      background: #ffffff;
      padding: 7px 15px 7px 13px;
      min-width: 227px;
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: space-between;
        &-ava {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      &-name {
        display: flex;
        flex-direction: column;
        gap: 5px;
        flex-grow: 1;
        font-size: 15px;
        p {
          color: #333333;
        }
        span {
          font-size: 12px;
          color: #828282;
        }
      }
      &-arrow {
        margin-left: 7px;
        width: 15px;
        height: 15px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &:hover {
        cursor: pointer;
      }
    }
    &-toggle {
      position: absolute;
      width: 100%;
      top: 100%;
      height: auto;
      opacity: 0;
      left: 0;
      background: #FFFFFF;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      z-index: -5;
      transition: all 0.5s ease;
      &-profile {
        padding: 7px 0 17px 0;
        background: #F3F33E;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 6px;
        .img {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 80px;
          height: 80px;
          border-radius: 50%;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        p {
          font-weight: 400;
          font-size: 15px;
          color: #333333;
        }
        span {
          font-weight: 400;
          font-size: 12px;
          color: #828282;
        }
      }
      &-buttons {
        display: flex;
        justify-content: space-between;
        padding: 15px;
        button {
          display: flex;
          gap: 5px;
          justify-content: center;
          align-items: center;
          background: transparent;
          outline: none;
          border: none;
          img {
            width: 15px;
            height: 15px;
          }
          &:hover {
            cursor: pointer;
            transform: scale(1.05);
          }
        }
      }
    }
    &-noticetoggle {
      position: absolute;
      width: 227px;
      top: 122%;
      right: -10px;
      height: auto;
      opacity: 0;
      background: #FFFFFF;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      z-index: -5;
      font-size: 12px;
      transition: all 0.5s ease;
      &-lists {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: stretch;
        background: #F2F2F2;
        padding: 14px 13px 5px 16px;
        p {
          -webkit-line-clamp: 3; /* Число отображаемых строк */
          display: -webkit-box; /* Включаем флексбоксы */
          -webkit-box-orient: vertical; /* Вертикальная ориентация */
          overflow: hidden;
        }
      }
      &-a {
        margin: 10px 13px !important;
        a {          
          color: #2F80ED;
        }
      }
      &::after, &::before {
        content: '';
        position: absolute;
        width: 0; 
        height: 0;
        margin: 0 auto;
        right: 15px; 
      }

      &::before {
        top: -26px;
        border: 15px solid transparent;
        border-bottom: 12px solid #F2F2F2;
      }

    }
  }
  @media screen and (max-width: $tablet) {
    display: none;
  }
}
.mheader {
  display: none;
  position: fixed;
  z-index: 999;
  bottom: 0;
  background: #FFFFFF;
  box-shadow: 0px -0.5px 0px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  left: 0;
  width: 100vw;
  align-items: stretch;
  justify-content: space-between;
  padding: 13px min(max(12px, calc(0.75rem + ((1vw - 3.2px) * 7.2727))), 20px) 7px min(max(12px, calc(0.75rem + ((1vw - 3.2px) * 7.2727))), 20px);
  font-size: 10px;
  &-item {
    opacity: 0.4;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 500;
    font-size: min(max(9px, calc(0.5625rem + ((1vw - 3.2px) * 0.9091))), 10px);
    gap: 3px;
  }
  @media screen and (max-width: $tablet) {
    display: flex;
  }
}

.mnav {
  display: none;
  position: fixed;
  z-index: 6;
  top: 0;
  transition: all 0.5s ease;
  left: 100%;
  bottom: 60px;
  width: 100vw;
  background: #fafafa;
  &-left {
    margin: 23px 0 7px 16px;
  }  
  @media screen and (max-width: $tablet) {
    display: block;
  }
}
.mnav-active {
  left: 0;
  transition: all 0.5s ease;
}
.mheadactive, .togac {
  opacity: 1 !important;
  z-index: 5;
}
</style>
